<template lang="pug">
  tr.table__row.table__row--body
    td(data-label="Ім’я постачальника / Покупця").table__cell {{ provider.name }}
    td(data-label="Постачальник / Покупець").table__cell {{ provider.type === 'buyer' ? 'base.buyer' : 'base.provider' | translate }}
    td(data-label="Інформація").table__cell
      .table__cell-wrapper
        p.table__cell-text {{ provider.info }}

        v-btn(
          icon
          @click="modals.showSettings = true"
          v-permission-hide="permissions.update_provider"
        ).table__button.table__button--settings-left
          icon-gear.table__settings

        v-dialog(
          max-width="348"
          v-model="modals.showSettings")
          settings-modal(
            :item="provider"
            @close="modals.showSettings = false")

        //- INFO: Поки в АПІ немає, якщо з'явиться відкоментуємо
        //- button(@click="isVisibleTooltip = !isVisibleTooltip").info-tooltip i
        //- .table__tooltip.table__tooltip--storage
          transition(name="fadex")
            v-tooltip.v-tooltip--tiny(
              v-if="isVisibleTooltip"
              :headers="tooltipHeaders")
</template>

<script>
  import { mapGetters } from 'vuex'

  import IconGear from '@/components/icons/IconGear'
  import SettingsModal from './modals/SettingsModal'
  import permissions from '@/util/permissions'

  export default {
    name: 'SuppliersTableRow',

    components: {
      IconGear,
      SettingsModal
    },

    props: {
      provider: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      isVisibleTooltip: false,
      tooltipHeaders: ['Примітка яка буде написана з приводу даного рецепта'],
      modals: {
        showSettings: false
      },
      permissions: permissions
    }),

    computed: {
      ...mapGetters(['user'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
